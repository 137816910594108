<template>
  <div class="points">
    <div v-if="mapData">
      <div style="margin-bottom:15px;" v-if="content.styleSettings">
        <v-btn outlined small color="primary" @click="content.styleSettings = false">
          <v-icon>mdi-chevron-left</v-icon> Back to map points settings
        </v-btn>
      </div>
      <div v-if="mapData && !content.styleSettings">
        <div>
          <label class="label" style="font-weight: bold; font-size: 20px"
          >General settings</label
          >
          <div style="padding:0 15px;">
            <div
                class="margin"
                style="display: flex; width: 100%; "
            >
              <div style="display:flex;align-items:center;flex:1;margin-right:15px;">
                <label class="label" style="display:block;margin-right:8px;margin-bottom:0;width:200px;">Latitude</label>
                <v-text-field
                    dense
                    hide-details
                    outlined
                    v-model="mapData.coordinates.lat"
                ></v-text-field>
              </div>
              <div style="display:flex;align-items:center;flex:1;">
                <label class="label" style="display:block;margin-right:8px;margin-bottom:0;width:200px;">Longitude</label>
                <v-text-field
                    dense
                    hide-details
                    outlined
                    v-model="mapData.coordinates.lon"
                ></v-text-field>
              </div>
            </div>
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="display:block;min-width:200px;margin-bottom:0;">Zoom range <b>{{ mapData.zoom }} eq</b></label>
              <div class="slider" style="width:100%;">
                <v-slider
                    :max="18"
                    :min="0"
                    v-model="mapData.zoom"
                    hide-details
                ></v-slider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="mapData && !content.styleSettings">
        <div class="points_footer">
          <v-btn dense small outlined color="primary" right @click="addCategory">+ Add category</v-btn>
        </div>
        <label class="label">You can add your custom points</label>

        <div
          v-for="(pointGroup,groupIndex) in mapData.point_categories "
          :key="pointGroup.id"
          class="point_block margin"
        >
        <div style="display:flex;justify-content:space-between;margin-bottom:5px;">
        <div v-if="pointGroup.hide">{{pointGroup.name[landing.current_lang]}}</div>
        <div v-if="!pointGroup.hide"></div>
        <div>
        <v-icon v-if="!pointGroup.hide"
                style="cursor: pointer"
                @click="hidePoint(pointGroup)">
          mdi-minus
        </v-icon>
        <v-icon v-if="pointGroup.hide"
                style="cursor: pointer"
                @click="hidePoint(pointGroup)">
          mdi-plus
        </v-icon>
        <v-icon
                style="cursor: pointer"
                @click="deleteCategory(pointGroup)">
          mdi-delete
        </v-icon>
        </div>
        </div>
        <div v-if="!pointGroup.hide">
          <div>
            <div style="display: flex;margin:15px 0;align-items: center;justify-content: space-between;">
            <div>Point Category</div>
            <div class="points_footer">
              <v-btn outlined dense small color="primary" right @click="addMapPoint(pointGroup.id)"
              >+ Add point</v-btn
              >
            </div>
            </div>
                <div class="margin" style="display:flex;align-items:center;">
                  <div style="display:flex;align-items:center;flex:1">
                    <label class="label" style="display:block;margin-bottom:0;width:200px;">Category name</label>
                    <v-text-field
                        outlined
                        dense
                        hide-details
                        v-model="pointGroup.name[landing.current_lang]"
                    ></v-text-field>
                  </div>
                  <div style="flex:1"></div>
                </div>
          </div>
          <div
            v-for="(point, index) in pointGroup.points"
            :key="point.id"
            class="point_block margin"
          >
            <div class="points_header">
            <div >
              <div v-if="point.hide" >
              {{point.name[landing.current_lang]}}</div>
            </div>
            <div class="points_buttons">
              <v-icon
                style="cursor: pointer"
                @click="hidePoint(point)"
                v-if="!point.hide"
                >mdi-minus</v-icon
              >
              <v-icon
                style="cursor: pointer"
                @click="hidePoint(point)"
                v-if="point.hide"
                >mdi-plus</v-icon
              >
              <v-icon style="cursor: pointer" @click="deletePointView(point)"
                >mdi-delete</v-icon
              >
              </div>
            </div>
            <div v-if="!point.hide">
            <div class="margin" @click="setIndexes(groupIndex,index)">
              <label class="label"> Add point main image </label>
              <vue-dropzone
                @vdropzone-drop="setIndexes(groupIndex,index)"
                :id="`mainDropzone_${groupIndex}_${index}`"
                :ref="`mainDropzone_${groupIndex}_${index}`"
                :destroyDropzone="false"
                :options="dropzoneOptions"
                @vdropzone-sending="(_file, xhr, formData) => customEvent(_file, xhr, formData,groupIndex,index)"
                @vdropzone-max-files-exceeded="(file) => maxFileSexceeded(file, groupIndex,index)"
                @vdropzone-removed-file="(file) => handleRemove(file, groupIndex,index)"
                @vdropzone-success="(file) => handleSuccess(file, groupIndex,index)"
                @vdropzone-mounted="mountDropzone"
              ></vue-dropzone>
            </div>
              <div
                  class="margin"
                  style="display: flex; width: 100%; "
              >
                <div style="display:flex;align-items:center;flex:1;margin-right:15px;">
                  <label class="label" style="display:block;margin-right:8px;margin-bottom:0;width:200px;">Link</label>
                  <v-text-field
                      dense
                      hide-details
                      outlined
                      v-model="point.object_url"
                  ></v-text-field>
                </div>
                <div style="display:flex;align-items:center;flex:1;">
                  <label class="label" style="display:block;margin-right:8px;margin-bottom:0;width:200px;">Change icon</label>
                  <v-autocomplete
                      dense
                      flat
                      outlined
                      solo
                      class="input input_dense"
                      :items="marker_icons"
                      hide-details
                      v-model="point.icon"
                  >
                    <template v-slot:selection="data">
                  <span
                      :class="`map-icon map-icon-${data.item
                      .split(' ')
                      .join('-')}`"
                      style="font-size: 20px; margin-right: 10px; color: #000"
                  ></span>
                      {{ data.item }}
                    </template>
                    <template v-slot:item="data">
                  <span
                      :class="`map-icon map-icon-${data.item
                      .split(' ')
                      .join('-')}`"
                      style="font-size: 20px; margin-right: 10px; color: #000"
                  ></span>
                      {{ data.item }}
                    </template>
                  </v-autocomplete>
                </div>
              </div>
            <div class="margin">
              <div
                style="
                  display: flex;
                  width: 100%;
                "
              >
                <div style="flex:1;">
                  <label class="label">Name</label>
                  <v-text-field
                      outlined
                      hide-details
                      dense
                      v-model="point.name[landing.current_lang]"
                  ></v-text-field>
                </div>
                <div style="width:20px;"></div>
                <div style="flex:1;">
                  <label class="label">Latitude</label>
                  <v-text-field
                    dense
                    hide-details
                    outlined
                    v-model="point.coordinates.lat"
                  ></v-text-field>
                </div>
                <div style="width:20px;"></div>
                <div style="flex:1;">
                  <label class="label">Longitude</label>
                  <v-text-field
                      dense
                      hide-details
                      outlined
                      v-model="point.coordinates.lon"
                  ></v-text-field>
                </div>
              </div>
            </div>
                <div class="margin">
                  <label class="label">Description</label>
                  <Editor
                    api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                    :init="options"
                    v-model="point.description[landing.current_lang]"
                  />
                </div>
<!--            <div class="points_footer">-->
<!--              <v-btn dense small color="primary" right @click="savePoint(point)">Save changes</v-btn>-->
<!--            </div>-->
          </div>
            </div>
        </div>
      </div>
          </div>
      <div v-if="content.styleSettings && content">
        <div class="margin">
          <label class="label"
            >Change block height
            <b>{{ content.styles.block_height }}</b>
            px</label
          >
          <div class="slider">
            <v-slider
              hide-details
              :min="100"
              :max="600"
              v-model="content.styles.block_height"
            ></v-slider>
          </div>
        </div>
        <div class="margin">
          <label class="label"
            >Change block width
            <b>{{ content.styles.block_width }}</b>
            px</label
          >
          <div class="slider">
            <v-slider
              hide-details
              :min="90"
              :max="300"
              v-model="content.styles.block_width"
            ></v-slider>
          </div>
        </div>
        <div class="margin">
          <label class="label"
            >Change image height
            <b>{{ content.styles.image_height }}</b>
            px</label
          >
          <div class="slider">
            <v-slider
              hide-details
              :min="90"
              :max="300"
              v-model="content.styles.image_height"
            ></v-slider>
          </div>
        </div>
        <div class="margin">
          <label class="label"
            >Change image width
            <b>{{ content.styles.image_width }}</b>
            %</label
          >
          <div class="slider">
            <v-slider
              hide-details
              :min="10"
              :max="100"
              v-model="content.styles.image_width"
            ></v-slider>
          </div>
        </div>
        <div class="margin">
          <label class="label"
            >Change block radius
            <b>{{ content.styles.radius }}</b>
            px</label
          >
          <div class="slider">
            <v-slider
              hide-details
              :min="0"
              :max="50"
              v-model="content.styles.radius"
            ></v-slider>
          </div>
        </div>
        <div class="margin colors">
          <label
            class="label"
            style="margin-bottom: 0; margin-right: 15px; min-width: 250px"
            >Change background color</label
          >
          <v-menu
            v-model="bg_color"
            top
            nudge-bottom="98"
            nudge-left="16"
            :close-on-content-click="false"
            z-index="21"
          >
            <template v-slot:activator="{ on }">
              <div
                :style="`background-color:${content.styles.bg_color}`"
                class="color"
                v-on="on"
              />
            </template>
            <v-card>
              <v-card-text class="pa-0">
                <v-color-picker v-model="content.styles.bg_color" flat />
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
        <div class="margin colors">
          <label
            class="label"
            style="margin-bottom: 0; margin-right: 15px; min-width: 250px"
            >Change title color</label
          >
          <v-menu
            v-model="title_color"
            top
            nudge-bottom="98"
            nudge-left="16"
            :close-on-content-click="false"
            z-index="21"
          >
            <template v-slot:activator="{ on }">
              <div
                :style="`background-color:${content.styles.title_color}`"
                class="color"
                v-on="on"
              />
            </template>
            <v-card>
              <v-card-text class="pa-0">
                <v-color-picker v-model="content.styles.title_color" flat />
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
        <div class="margin colors">
          <label
            class="label"
            style="margin-bottom: 0; margin-right: 15px; min-width: 250px"
            >Change marker shape color</label
          >
          <v-menu
            v-model="shape_color"
            top
            nudge-bottom="98"
            nudge-left="16"
            :close-on-content-click="false"
            z-index="21"
          >
            <template v-slot:activator="{ on }">
              <div
                :style="`background-color:${content.styles.marker_shape_color}`"
                class="color"
                v-on="on"
              />
            </template>
            <v-card>
              <v-card-text class="pa-0">
                <v-color-picker
                  v-model="content.styles.marker_shape_color"
                  flat
                />
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
        <div class="margin colors">
          <label
            class="label"
            style="margin-bottom: 0; margin-right: 15px; min-width: 250px"
            >Change marker icon color</label
          >
          <v-menu
            v-model="icon_color"
            top
            nudge-bottom="98"
            nudge-left="16"
            :close-on-content-click="false"
            z-index="21"
          >
            <template v-slot:activator="{ on }">
              <div
                :style="`background-color:${content.styles.marker_icon_color}`"
                class="color"
                v-on="on"
              />
            </template>
            <v-card>
              <v-card-text class="pa-0">
                <v-color-picker
                  v-model="content.styles.marker_icon_color"
                  flat
                />
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
        <div class="margin colors">
          <label
            class="label"
            style="margin-bottom: 0; margin-right: 15px; min-width: 250px"
            >Change slider border color</label
          >
          <v-menu
            v-model="border_color"
            top
            nudge-bottom="98"
            nudge-left="16"
            :close-on-content-click="false"
            z-index="21"
          >
            <template v-slot:activator="{ on }">
              <div
                :style="`background-color:${content.styles.border_color}`"
                class="color"
                v-on="on"
              />
            </template>
            <v-card>
              <v-card-text class="pa-0">
                <v-color-picker v-model="content.styles.border_color" flat />
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
        <div class="margin">
          <label class="label">Change shape</label>

          <v-autocomplete
            dense
            flat
            outlined
            solo
            class="input input_dense"
            :items="shape_icons"
            hide-details
            v-model="content.styles.marker_shape"
          >
            <template v-slot:selection="data">
              <span
                :class="`map-icon map-icon-${data.item
                  .toLowerCase()
                  .replace('_', '-')}`"
                style="font-size: 20px; margin-right: 10px"
                :style="`color:${content.styles.marker_shape_color};`"
              ></span>
              {{ data.item.toLowerCase().replace("_", " ") }}
            </template>
            <template v-slot:item="data">
              <span
                :class="`map-icon map-icon-${data.item
                  .toLowerCase()
                  .replace('_', '-')}`"
                style="font-size: 20px; margin-right: 10px"
                :style="`color:${content.styles.marker_shape_color};`"
              ></span>
              {{ data.item.toLowerCase().replace("_", " ") }}
            </template>
          </v-autocomplete>
        </div>
      </div>
    </div>
    <div class="points_footer" style="margin-top: 15px;">
      <v-btn dense color="primary" right @click="saveMap">Save points</v-btn>
    </div>
    <PopupDelete
      ref="PopupDeletePoint"
      title="Are you sure about delete point?"
      @confirm="pointDelete"
    />
    <PopupDelete
      ref="PopupDeletePointCategory"
      title="Are you sure about delete category?"
      @confirm="deleteCategoryConfirm"
    />
    <PopupCrop
      title='Change image before upload?'
      ref="PopupCrop"
      @confirm="openEditor"
      @save="saveImg"
    />
    <PopupSuccess
      ref="PopupSuccess"
      title="Success"
      content="Saved successfully"/>
    <PopupConfirm
        ref="PopupConfirm"
        @confirm="confirmCopy"
        :title="
        `Are you sure about copy content from ${defaultLangName} to ${currentLangName}`
      "
    />
  </div>
</template>

<script>
    import {mapActions, mapMutations, mapState} from "vuex";
import vueDropzone from "vue2-dropzone";

import PopupDelete from "@/components/Popup/PopupDelete.vue";
import Cropper from "cropperjs";
import "../../../../node_modules/cropperjs/dist/cropper.min.css";
import PopupCrop from "@/components/Popup/PopupCrop.vue"
import PopupSuccess from "@/components/Popup/PopupSuccess";
import PopupConfirm from "@/components/Popup/PopupConfirm"
export default {
  name: "typeMapPoints",
  props: ["item", "content","selectedMenuID"],
  components: {
    PopupSuccess,
    vueDropzone,

    PopupDelete,
    PopupCrop,
    PopupConfirm
  },
  data() {
    return {
      deleteCategoryObj:null,
      border_color:false,
      pointGroupIndex:null,
      pointIndex:null,
      options: {
        images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: 'jpg,svg,webp,gif,png',
        images_upload_handler: async  (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
          formData.append("lending_settings_id", this.$store.state.landing.settings_id);
          formData.append("image", _file);
          formData.append("file", _file);
          const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
          success(data);
        },
        plugins:
          "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",

        mobile: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: 'jpg,svg,webp,gif,png',
        images_upload_handler: async  (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
          formData.append("lending_settings_id", this.$store.state.landing.settings_id);
          formData.append("image", _file);
          formData.append("file", _file);
          const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
          success(data);
        },
        plugins:
            "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
        },
        menu: {
          tc: {
            title: "Comments",
            items: "addcomment showcomments deleteallconversations",
          },
        },
        menubar: "file edit view insert format tools table tc help",
        toolbar:
          "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
        autosave_ask_before_unload: true,min_height:200,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        link_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_class_list: [
          { title: "None", value: "" },
          { title: "Some class", value: "class-name" },
        ],
        importcss_append: true,
        templates: [
          {
            title: "New Table",
            description: "creates a new table",
            content:
              '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
          },
          {
            title: "Starting my story",
            description: "A cure for writers block",
            content: "Once upon a time...",
          },
          {
            title: "New list with dates",
            description: "New List with dates",
            content:
              '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
          },
        ],
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        height: 400,
        image_caption: true,
        quickbars_selection_toolbar:
          "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        content_style: ".mymention{ color: gray; }",
        contextmenu: "link image imagetools table configurepermanentpen",
        skin: "oxide",

          setup:function(editor){
              editor.ui.registry.addButton('custom_button', {
                  text: 'Add Button',
                  onAction: function() {
                      // Open a Dialog
                      editor.windowManager.open({
                          title: 'Add custom button',
                          body: {
                              type: 'panel',
                              items: [{
                                  type: 'input',
                                  name: 'button_label',
                                  label: 'Button label',
                                  flex: true
                              },{
                                  type: 'input',
                                  name: 'button_href',
                                  label: 'Button href',
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_target',
                                  label: 'Target',
                                  items: [
                                      {text: 'None', value: ''},
                                      {text: 'New window', value: '_blank'},
                                      {text: 'Self', value: '_self'},
                                      {text: 'Parent', value: '_parent'}
                                  ],
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_rel',
                                  label: 'Rel',
                                  items: [
                                      {text: 'No value', value: ''},
                                      {text: 'Alternate', value: 'alternate'},
                                      {text: 'Help', value: 'help'},
                                      {text: 'Manifest', value: 'manifest'},
                                      {text: 'No follow', value: 'nofollow'},
                                      {text: 'No opener', value: 'noopener'},
                                      {text: 'No referrer', value: 'noreferrer'},
                                      {text: 'Opener', value: 'opener'}
                                  ],
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_style',
                                  label: 'Style',
                                  items: [
                                      {text: 'Success', value: 'success'},
                                      {text: 'Info', value: 'info'},
                                      {text: 'Warning', value: 'warning'},
                                      {text: 'Error', value: 'error'}
                                  ],
                                  flex: true
                              }]
                          },
                          onSubmit: function (api) {

                              var html = '<a href="'+api.getData().button_href+'" class="v-btn v-btn--outlined theme--light v-size--small '+api.getData().button_style+'--text" rel="'+api.getData().button_rel+'" target="'+api.getData().button_target+'">'+api.getData().button_label+'</a>';

                              // insert markup
                              editor.insertContent(html);

                              // close the dialog
                              api.close();
                          },
                          buttons: [
                              {
                                  text: 'Close',
                                  type: 'cancel',
                                  onclick: 'close'
                              },
                              {
                                  text: 'Insert',
                                  type: 'submit',
                                  primary: true,
                                  enabled: false
                              }
                          ]
                      });
                  }
              });
          },
      content_css: ["/custom_tinymce.css"],
      },
      dropzoneOptions: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        addRemoveLinks: true,
        autoProcessQueue:false,
        maxFilesize: 3,
        maxFiles: 2,
        dictFileTooBig: "File is too big ({{filesize}}mb). Max file size: 3mb",
        thumbnail: (file, dataUrl) => {
          if (file.cropped) {
            return;
          }else{
            this.$refs.PopupCrop.open(file);
          }
        },
      },
      shape_color: false,
      icon_color: false,
      settings: false,
      bg_color: false,
      deleteId: null,
      title_color: false,
      shape_icons: [
        "MAP_PIN",
        "SQUARE_PIN",
        "SHIELD",
        "ROUTE",
        "SQUARE",
        "SQUARE_ROUNDED",
      ],
      marker_icons: [
        "abseiling",
        "accounting",
        "airport",
        "amusement park",
        "aquarium",
        "archery",
        "art gallery",
        "assistive listening system",
        "atm",
        "audio description",
        "bakery",
        "bank",
        "bar",
        "baseball",
        "beauty salon",
        "bicycle store",
        "bicycling",
        "boat ramp",
        "boat tour",
        "boating",
        "book store",
        "bowling alley",
        "braille",
        "bus station",
        "cafe",
        "campground",
        "canoe",
        "car dealer",
        "car rental",
        "car repair",
        "car wash",
        "casino",
        "cemetery",
        "chairlift",
        "church",
        "circle",
        "city hall",
        "climbing",
        "closed captioning",
        "clothing store",
        "compass",
        "convenience store",
        "courthouse",
        "cross country skiing",
        "crosshairs",
        "dentist",
        "department store",
        "diving",
        "doctor",
        "electrician",
        "electronics store",
        "embassy",
        "expand",
        "female",
        "finance",
        "fire station",
        "fish cleaning",
        "fishing pier",
        "fishing",
        "florist",
        "food",
        "fullscreen",
        "funeral home",
        "furniture store",
        "gas station",
        "general contractor",
        "golf",
        "grocery or supermarket",
        "gym",
        "hair care",
        "hang gliding",
        "hardware store",
        "health",
        "hindu temple",
        "horse riding",
        "hospital",
        "ice fishing",
        "ice skating",
        "inline skating",
        "insurance agency.txt",
        "jet skiing",
        "jewelry store",
        "kayaking",
        "laundry",
        "lawyer",
        "library",
        "liquor store",
        "local government",
        "location arrow",
        "locksmith",
        "lodging",
        "low vision access",
        "male",
        "map pin",
        "marina",
        "mosque",
        "motobike trail",
        "movie rental",
        "movie theater",
        "moving company",
        "museum",
        "natural feature",
        "night club",
        "open captioning",
        "painter",
        "park",
        "parking",
        "pet store",
        "pharmacy",
        "physiotherapist",
        "place of worship",
        "playground",
        "plumber",
        "point of interest",
        "police",
        "political",
        "post box",
        "post office",
        "postal code prefix",
        "postal code",
        "rafting",
        "real estate agency.txt",
        "restaurant",
        "roofing contractor",
        "route pin",
        "route",
        "rv park",
        "sailing",
        "school",
        "scuba diving",
        "search",
        "shield",
        "shopping mall",
        "sign language",
        "skateboarding",
        "ski jumping",
        "skiing",
        "sledding",
        "snow shoeing",
        "snow",
        "snowboarding",
        "snowmobile",
        "spa",
        "square pin",
        "square rounded",
        "square",
        "stadium",
        "storage",
        "store",
        "subway station",
        "surfing",
        "swimming",
        "synagogue",
        "taxi stand",
        "tennis",
        "toilet",
        "trail walking",
        "train station",
        "transit station",
        "travel agency.txt",
        "unisex",
        "university",
        "veterinary care",
        "viewing",
        "volume control telephone",
        "walking",
        "waterskiing",
        "whale watching",
        "wheelchair",
        "wind surfing",
        "zoo",
        "zoom in alt",
        "zoom in",
        "zoom out alt",
        "zoom out",
      ],
    };
  },
  computed: {
    ...mapState("points", ["mapDataList"]),
    ...mapState(["landing"]),
    currentLangName() {
      return this.landing.advanced.langs.find(
          (x) => x.value === this.landing.current_lang
      ).name;
    },
    defaultLangName() {
      return this.landing.advanced.langs.find(
          (x) => x.value === this.landing.advanced.default_lang
      ).name;
    },
    activeLang: {
      set: function (val) {},
      get: function () {
        return this.landing.advanced.selected_langs.indexOf(
          this.landing.current_lang
        );
      },
    },
    mapData(){
      if(this.mapDataList){
          let id;
          if(this.item.type === 10){
              id = +(this.selectedMenuID+""+this.item.id+'000');
          }else{
              id = this.item.id;
          }
        const candidate = this.mapDataList.find(x => x.menu_id === id);
        if(candidate){
          return candidate;
        }else{
          return null
        }
      }else{
        return null
      }
    }
  },
  methods: {
      ...mapMutations("landing", ["setIsDirty", "setIsAbleToWatch"]),
    ...mapActions("points", ["getMapData", "addPoint", "deletePoint","editPointCategory","savePoint","addPointCategory","deletePointCategory","updateMapData"]),
    copyLang(){
      this.$refs.PopupConfirm.open();
    },
    mountDropzone(){
        for (let i = 0; i < this.mapData.point_categories.length; i++) {
          for (
              let index = 0;
              index < this.mapData.point_categories[i].points.length;
              index++
          ) {
            let item = this.mapData.point_categories[i].points[index];
            if (item.main_image) {
              let mockFile = {
                accepted: true,
                cropped: true,
                kind: "image",
                ...item,
              };
              if(this.$refs[`mainDropzone_${i}_${index}`]){
                if (
                  this.$refs[`mainDropzone_${i}_${index}`][0].dropzone &&
                  this.$refs[`mainDropzone_${i}_${index}`][0].dropzone.files.length === 0
                ) {
                  this.$refs[`mainDropzone_${i}_${index}`][0].manuallyAddFile(
                    mockFile,
                    item.main_image
                  );
                  this.$refs[`mainDropzone_${i}_${index}`][0].dropzone[
                    "previewsContainer"
                    ].children[1].children[0].children[0].src = item.main_image;
                  this.$refs[`mainDropzone_${i}_${index}`][0].dropzone[
                    "previewsContainer"
                    ].children[1].children[0].style.borderRadius = 0;
                  this.$refs[`mainDropzone_${i}_${index}`][0].dropzone[
                    "previewsContainer"
                    ].children[1].children[1].style.background = "none";
                  this.$refs[`mainDropzone_${i}_${index}`][0].dropzone.emit(
                    "complete",
                    mockFile
                  );
                }
              }
            }
          }
        }
    },
    confirmCopy() {
      let defaultContent = JSON.parse(
          JSON.stringify(this.item.content.en[10])
      );
      this.item.content[this.landing.current_lang][10].styles =
          defaultContent.styles;
      this.item.content[this.landing.current_lang][10].active_slider =
          defaultContent.active_slider;
      this.item.content[this.landing.current_lang][10].slider_menu =
          defaultContent.slider;
      for(let indexMap = 0;indexMap<this.mapDataList.length;indexMap++){
        for(let indexCategory = 0;indexCategory<this.mapDataList[indexMap].point_categories.length;indexCategory++){
          this.mapDataList[indexMap].point_categories[indexCategory].name[this.landing.current_lang] = this.mapDataList[indexMap].point_categories[indexCategory].name.en.split('').join('');
          for(let indexPoint = 0;indexPoint<this.mapDataList[indexMap].point_categories[indexCategory].points.length;indexPoint++){
            this.mapDataList[indexMap].point_categories[indexCategory].points[indexPoint].name[this.landing.current_lang] = this.mapDataList[indexMap].point_categories[indexCategory].points[indexPoint].name.en.split('').join('')
            this.mapDataList[indexMap].point_categories[indexCategory].points[indexPoint].description[this.landing.current_lang] = this.mapDataList[indexMap].point_categories[indexCategory].points[indexPoint].description.en.split('').join('')
          }
        }
      }
      this.saveMap();
    },
    setIndexes(groupIndex,index){
      this.pointGroupIndex = groupIndex;
      this.pointIndex = index;
    },
    async saveMap(){
      await this.updateMapData(this.mapData);
      for(let i = 0;i<this.mapData.point_categories.length;i++){
        await this.editPointCategory(this.mapData.point_categories[i]);
        for(let index = 0;index<this.mapData.point_categories[i].points.length;index++){
          await this.savePoint(this.mapData.point_categories[i].points[index]);
        }
      }
      await this.$store.dispatch("landing/saveLanding", this.$route.params.id);
      this.$emit('save');
      this.setIsDirty(false);
      this.setIsAbleToWatch(true);
      this.$refs.PopupSuccess.open();
    },
    saveImg(file){
      file.cropped = true;
      this.$refs[`mainDropzone_${this.pointGroupIndex}_${this.pointIndex}`][0].dropzone.removeAllFiles();
      this.$refs[`mainDropzone_${this.pointGroupIndex}_${this.pointIndex}`][0].dropzone.addFile(file);
      this.$refs[`mainDropzone_${this.pointGroupIndex}_${this.pointIndex}`][0].dropzone.processQueue();
       this.$refs[`mainDropzone_${this.pointGroupIndex}_${this.pointIndex}`][0].dropzone[
        "previewsContainer"
      ].children[1].children[0].children[0].src = file.dataURL;
       this.$refs[`mainDropzone_${this.pointGroupIndex}_${this.pointIndex}`][0].dropzone[
        "previewsContainer"
      ].children[1].children[0].style.borderRadius = 0;
       this.$refs[`mainDropzone_${this.pointGroupIndex}_${this.pointIndex}`][0].dropzone[
        "previewsContainer"
      ].children[1].children[1].style.background = "none";
    },
    openEditor(file){
      let editor = document.createElement("div");
          editor.style.position = "fixed";
          editor.style.left = 0;
          editor.style.right = 0;
          editor.style.top = 0;
          editor.style.bottom = 0;
          editor.style.width = "66%";
          editor.style.zIndex = 9999;
          editor.style.backgroundColor = "#000";
          document.body.appendChild(editor);
          let buttonConfirm = document.createElement("button");
          buttonConfirm.style.position = "absolute";
          buttonConfirm.style.right = "10px";
          buttonConfirm.style.top = "10px";
          buttonConfirm.style.zIndex = 9999;
          buttonConfirm.textContent = "Save image";
          buttonConfirm.className =
            "v-btn v-size--small confirm-control";
          editor.appendChild(buttonConfirm);
          let buttonCancel = document.createElement("button");
          buttonCancel.style.position = "absolute";
          buttonCancel.style.right = "135px";
          buttonCancel.style.top = "10px";
          buttonCancel.style.zIndex = 9999;
          buttonCancel.textContent = "Cancel";
          buttonCancel.className =
            "v-btn v-size--small confirm-control";
          editor.appendChild(buttonCancel);
          buttonCancel.addEventListener("click", () => {
            this.$refs[`mainDropzone_${this.pointGroupIndex}_${this.pointIndex}`][0].dropzone.removeAllFiles();
            document.body.removeChild(editor);
            cropper.destroy();
          });
          let buttonRotateLeft = document.createElement("button");
          buttonRotateLeft.className = "cropper-controls";
          buttonRotateLeft.style.left = "24px";
          buttonRotateLeft.innerHTML =
            `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M13,4.07V1L8.45,5.55L13,10V6.09C15.84,6.57 18,9.03 18,12C18,14.97 15.84,17.43 13,17.91V19.93C16.95,19.44 20,16.08 20,12C20,7.92 16.95,4.56 13,4.07M7.1,18.32C8.26,19.22 9.61,19.76 11,19.93V17.9C10.13,17.75 9.29,17.41 8.54,16.87L7.1,18.32M6.09,13H4.07C4.24,14.39 4.79,15.73 5.69,16.89L7.1,15.47C6.58,14.72 6.23,13.88 6.09,13M7.11,8.53L5.7,7.11C4.8,8.27 4.24,9.61 4.07,11H6.09C6.23,10.13 6.58,9.28 7.11,8.53Z" />
</svg>`;
          editor.appendChild(buttonRotateLeft);
          let buttonRotateRight = document.createElement("button");
          buttonRotateRight.className = "cropper-controls";
          buttonRotateRight.style.left = "64px";
          buttonRotateRight.innerHTML =
            `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M16.89,15.5L18.31,16.89C19.21,15.73 19.76,14.39 19.93,13H17.91C17.77,13.87 17.43,14.72 16.89,15.5M13,17.9V19.92C14.39,19.75 15.74,19.21 16.9,18.31L15.46,16.87C14.71,17.41 13.87,17.76 13,17.9M19.93,11C19.76,9.61 19.21,8.27 18.31,7.11L16.89,8.53C17.43,9.28 17.77,10.13 17.91,11M15.55,5.55L11,1V4.07C7.06,4.56 4,7.92 4,12C4,16.08 7.05,19.44 11,19.93V17.91C8.16,17.43 6,14.97 6,12C6,9.03 8.16,6.57 11,6.09V10L15.55,5.55Z" />
</svg>`;
          editor.appendChild(buttonRotateRight);
          let buttonCrop = document.createElement("button");
          buttonCrop.className = "cropper-controls active-control";
          buttonCrop.style.left = "104px";
          buttonCrop.innerHTML =
            `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M7,17V1H5V5H1V7H5V17A2,2 0 0,0 7,19H17V23H19V19H23V17M17,15H19V7C19,5.89 18.1,5 17,5H9V7H17V15Z" />
</svg>`;
          editor.appendChild(buttonCrop);
          let buttonZoomPlus = document.createElement("button");
          buttonZoomPlus.className = "cropper-controls";
          buttonZoomPlus.style.left = "144px";
          buttonZoomPlus.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14L20.5,19L19,20.5L14,15.5V14.71L13.73,14.43C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.43,13.73L14.71,14H15.5M9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14M12,10H10V12H9V10H7V9H9V7H10V9H12V10Z" />
</svg>`;
          editor.appendChild(buttonZoomPlus);
          let buttonZoomMinus = document.createElement("button");
          buttonZoomMinus.className = "cropper-controls";
          buttonZoomMinus.style.left = "184px";
          buttonZoomMinus.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14H14.71L14.43,13.73C15.41,12.59 16,11.11 16,9.5A6.5,6.5 0 0,0 9.5,3A6.5,6.5 0 0,0 3,9.5A6.5,6.5 0 0,0 9.5,16C11.11,16 12.59,15.41 13.73,14.43L14,14.71V15.5L19,20.5L20.5,19L15.5,14M9.5,14C7,14 5,12 5,9.5C5,7 7,5 9.5,5C12,5 14,7 14,9.5C14,12 12,14 9.5,14M7,9H12V10H7V9Z" />
</svg>`;
          editor.appendChild(buttonZoomMinus);
          let buttonLeftRight = document.createElement("button");
          buttonLeftRight.className = "cropper-controls";
          buttonLeftRight.style.left = "224px";
          buttonLeftRight.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M6.45,17.45L1,12L6.45,6.55L7.86,7.96L4.83,11H19.17L16.14,7.96L17.55,6.55L23,12L17.55,17.45L16.14,16.04L19.17,13H4.83L7.86,16.04L6.45,17.45Z" />
</svg>`;
          editor.appendChild(buttonLeftRight);
          let buttonUpDown = document.createElement("button");
          buttonUpDown.className = "cropper-controls";
          buttonUpDown.style.left = "264px";
          buttonUpDown.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M17.45,17.55L12,23L6.55,17.55L7.96,16.14L11,19.17V4.83L7.96,7.86L6.55,6.45L12,1L17.45,6.45L16.04,7.86L13,4.83V19.17L16.04,16.14L17.45,17.55Z" />
</svg>`;
          editor.appendChild(buttonUpDown);
          buttonConfirm.addEventListener("click", () => {
            let canvas = cropper.getCroppedCanvas({
              width: file.width,
              height: file.height,
              imageSmoothingEnabled: "false",
              imageSmoothingQuality: "high",
            });
            canvas.toBlob((blob) => {
              this.$refs[`mainDropzone_${this.pointGroupIndex}_${this.pointIndex}`][0].dropzone.createThumbnail(
                blob,
                canvas.width,
                canvas.height,
                this.$refs[`mainDropzone_${this.pointGroupIndex}_${this.pointIndex}`][0].dropzone.options.thumbnailMethod,
                false,
                (dataURL) => {
                  let newFile = this.dataURItoBlob(dataURL);
                  newFile.cropped = true;
                  newFile.name = file.name;
                  this.$refs[`mainDropzone_${this.pointGroupIndex}_${this.pointIndex}`][0].dropzone.removeAllFiles();
                  this.$refs[`mainDropzone_${this.pointGroupIndex}_${this.pointIndex}`][0].dropzone.addFile(newFile);
                  this.$refs[`mainDropzone_${this.pointGroupIndex}_${this.pointIndex}`][0].dropzone.processQueue();
                  this.$refs[`mainDropzone_${this.pointGroupIndex}_${this.pointIndex}`][0].dropzone[
                    "previewsContainer"
                  ].children[1].children[0].children[0].src = dataURL;
                  this.$refs[`mainDropzone_${this.pointGroupIndex}_${this.pointIndex}`][0].dropzone[
                    "previewsContainer"
                  ].children[1].children[0].style.borderRadius = 0;
                  this.$refs[`mainDropzone_${this.pointGroupIndex}_${this.pointIndex}`][0].dropzone[
                    "previewsContainer"
                  ].children[1].children[1].style.background = "none";
                  document.body.removeChild(editor);
                  cropper.destroy();
                }
              );
            });
          });
          buttonRotateRight.addEventListener("click", () => {
            if (!buttonRotateRight.classList.contains("active-control")) {
              let prevBtn = editor.getElementsByClassName("active-control");
              prevBtn[0].classList.remove("active-control");
              buttonRotateRight.classList.add("active-control");
            }
            cropper.rotate(45);
          });
          buttonRotateLeft.addEventListener("click", () => {
            if (!buttonRotateLeft.classList.contains("active-control")) {
              let prevBtn = editor.getElementsByClassName("active-control");
              prevBtn[0].classList.remove("active-control");
              buttonRotateLeft.classList.add("active-control");
            }
            cropper.rotate(-45);
          });
          buttonCrop.addEventListener("click", () => {
            if (!buttonCrop.classList.contains("active-control")) {
              let prevBtn = editor.getElementsByClassName("active-control");
              prevBtn[0].classList.remove("active-control");
              buttonCrop.classList.add("active-control");
            }
            cropper.setDragMode("crop");
          });
          buttonZoomPlus.addEventListener("click", () => {
            if (!buttonZoomPlus.classList.contains("active-control")) {
              let prevBtn = editor.getElementsByClassName("active-control");
              prevBtn[0].classList.remove("active-control");
              buttonZoomPlus.classList.add("active-control");
            }
            cropper.zoom(0.1);
          });
          buttonZoomMinus.addEventListener("click", () => {
            if (!buttonZoomMinus.classList.contains("active-control")) {
              let prevBtn = editor.getElementsByClassName("active-control");
              prevBtn[0].classList.remove("active-control");
              buttonZoomMinus.classList.add("active-control");
            }
            cropper.zoom(-0.1);
          });
          buttonLeftRight.addEventListener("click", () => {
            if (!buttonLeftRight.classList.contains("active-control")) {
              let prevBtn = editor.getElementsByClassName("active-control");
              prevBtn[0].classList.remove("active-control");
              buttonLeftRight.classList.add("active-control");
            }
            if (cropper.getData().scaleX === 1) {
              cropper.scaleX(-1);
            } else {
              cropper.scaleX(1);
            }
          });
          buttonUpDown.addEventListener("click", () => {
            if (!buttonUpDown.classList.contains("active-control")) {
              let prevBtn = editor.getElementsByClassName("active-control");
              prevBtn[0].classList.remove("active-control");
              buttonUpDown.classList.add("active-control");
            }
            if (cropper.getData().scaleY === 1) {
              cropper.scaleY(-1);
            } else {
              cropper.scaleY(1);
            }
          });
          let image = new Image();
          image.src = URL.createObjectURL(file);
          editor.appendChild(image);
          const candidate = this.mapData.point_categories[this.pointGroupIndex].points[this.pointIndex];
          candidate.main_image = "1";
          let cropper = new Cropper(image, {
            preview: ".point_"+candidate.id,
            aspectRatio:
              (this.content.styles.block_width/100)*+this.content.styles.image_width /
              +this.content.styles.image_height,
          });
    },
    dataURItoBlob(dataURI) {
      let byteString = atob(dataURI.split(",")[1]);
      let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    hidePoint(point) {
      if(point.hide){
        point.hide = 0;
      }else{
        point.hide = 1;
      }
    },
    deleteCategory(category){
      this.deleteCategoryObj = category;
      this.$refs.PopupDeletePointCategory.open()
    },
    async deleteCategoryConfirm(){
      await this.deletePointCategory(this.deleteCategoryObj);
      this.deleteCategoryObj = null;
    },
    showSettings() {
      this.settings = !this.settings;
    },
    async pointDelete() {
      await this.deletePoint(this.deleteId);
      this.deleteId = null;
    },
    deletePointView(point) {
      this.$refs.PopupDeletePoint.open();
      this.deleteId = point;
    },
    maxFileSexceeded(file,groupIndex, index) {
      this.$refs[`mainDropzone_${groupIndex}_${index}`][0].removeAllFiles();
      this.$refs[`mainDropzone_${groupIndex}_${index}`][0].addFile(file);
    },
    handleRemove(file,groupIndex, index) {
      this.mapData.point_categories[groupIndex].points[index].main_image = "";
    },
    handleSuccess(file,groupIndex, index) {
      this.mapData.point_categories[groupIndex].points[index].main_image = file.xhr.response;
    },
    customEvent(_file, xhr, formData,groupIndex,index) {
      this.pointGroupIndex = groupIndex;
      this.pointIndex = index;
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${this.$store.state.token}`
      );
      formData.append(
        "lending_settings_id",
        this.$store.state.landing.settings_id
      );
      formData.append("image", _file);
    },
    changeZoomShow(val) {
      if (val) {
        this.mapData.show_zoom = 1;
      } else {
        this.mapData.show_zoom = 0;
      }
    },
    changeShowFullscreen(val) {
      if (val) {
        this.mapData.show_fullscreen = 1;
      } else {
        this.mapData.show_fullscreen = 0;
      }
    },
    changeMapType(val) {
      if (val) {
        this.mapData.map_type = 1;
      } else {
        this.mapData.map_type = 0;
      }
    },
    async addCategory(){
        let id;
        if(this.item.type === 10){
            id = +(this.selectedMenuID+""+this.item.id+'000');
        }else{
            id = this.item.id;
        }
      let category = {
        hotel_id:+this.$route.params.id,
        map_id: this.mapData.id,
        menu_id: id,
        hide:0,
        name:{
          en:"default category name"
        }
      }
      await this.addPointCategory(category);
    },
    async addMapPoint(group) {
        let id;
        if(this.item.type === 10){
            id = +(this.selectedMenuID+""+this.item.id+'000');
        }else{
            id = this.item.id;
        }
      let defaultPoint = {
        hotel_id: +this.$route.params.id,
        map_id: this.mapData.id,
        menu_id: id,
        point_category_id:group,
        name: {
          en: "Default name",
        },
        description: {
          en: "Default description",
        },
        category: {
          en: "Default description",
        },
        icon: "restaurant",
        main_image: "",
        images: [],
        hide:0,
        video_url: [],
        coordinates: {
          lat: 45.81,
          lon: 15.98,
        },
        object_url: "",
      };
      await this.addPoint(defaultPoint);
    },
  },
  async mounted() {
      let id;
      if(this.item.type === 10){
          id = +(this.selectedMenuID+""+this.item.id+'000');
      }else{
          id = this.item.id;
      }
    const payload = {
      hotel_id: this.$route.params.id,
      menu_id: id,
    };
    await this.getMapData(payload);
  },
};
</script>

<style lang="scss" scoped>
.points_footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.margin {
  margin-bottom: 15px;
}
.points {
  padding: 15px;
}
.coordinates {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.points_buttons {
  display: flex;
  justify-self: flex-end;
  justify-content: flex-end;
  align-items: center;
}
.point_block {
  border: 1px solid rgb(238, 238, 238);
  border-radius: 5px;
  padding: 15px;
}
.colors {
  display: flex;
  align-items: center;
}
.points_header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
